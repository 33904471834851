<template>
  <div class="user-documents">
    <div class="user-documents-item skeleton" @click="viewFiles(0)" cursor="pointer">
      <img alt="Image" :src="user.identity.id_front.location" />
    </div>
    <div class="user-documents-item skeleton" @click="viewFiles(1)" cursor="pointer">
      <img alt="Image" :src="user.identity.id_back.location" />
    </div>
    <div class="user-documents-item skeleton" @click="viewFiles(2)" cursor="pointer">
      <img alt="Image" :src="user.identity.face_id.location" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["user"],
  methods: {
    viewFiles: function(num) {
      const media = [
        { type: "image/jpg", src: this.user.identity.id_front.location },
        { type: "image/jpg", src: this.user.identity.id_back.location },
        { type: "image/jpg", src: this.user.identity.face_id.location },
      ];

      const fisrt = media[num];
      const current = media.filter((item) => item.src !== fisrt.src);
      current.unshift(fisrt);

      this.Media(current);
    },
  },
};
</script>

<style lang="scss" scoped>
.user-documents {
  display: flex;
  align-items: center;
  gap: $mpadding;
  &-item {
    flex: 3.33;
  }
  img {
    max-width: 100%;
  }
}
</style>
